import React from "react";
import Carousel from "better-react-carousel";
import { useTranslation } from "react-i18next";

import bgBot from "../../images/bg05.JPG";
import img02 from "../../images/02.png";
import img03 from "../../images/03.png";
import img04 from "../../images/04.png";
import img05 from "../../images/05.png";
import img06 from "../../images/06.png";
import img07 from "../../images/07.png";
import img09 from "../../images/09.png";
import img010 from "../../images/010.png";
import img011 from "../../images/011.png";
import img012 from "../../images/012.png";
import img013 from "../../images/013.png";
import img019 from "../../images/019.png";

const MyDot = ({ isActive }) => (
  <span
    style={{
      display: "inline-block",
      height: isActive ? "10px" : "5px",
      width: isActive ? "10px" : "5px",
      background: "#2dff03",
      borderRadius: 10,
    }}
  ></span>
);

const datImg = [
  {
    id: "02",
    img: img02,
    title: "title 02",
    caption: "Connect and view friends post",
  },
  {
    id: "03",
    img: img03,
    title: "title 03",
    caption: "Log catches are organized by date",
  },
  {
    id: "04",
    img: img04,
    title: "title 04",
    caption: "7 days marine weather forecast",
  },
  {
    id: "05",
    img: img05,
    title: "title 05",
    caption: "Solunar and Tide data",
  },
  {
    id: "06",
    img: img06,
    title: "title 06",
    caption: "Temperature data",
  },
  {
    id: "07",
    img: img07,
    title: "title 07",
    caption: "Map",
  },
  {
    id: "09",
    img: img09,
    title: "title 09",
    caption: "View log catches",
  },
  {
    id: "010",
    img: img010,
    title: "title 10",
    caption: "Log Cathces past weather and gear used",
  },
  {
    id: "011",
    img: img011,
    title: "title 011",
    caption: "View previous catch location",
  },
  {
    id: "012",
    img: img012,
    title: "title 012",
    caption: "View Global post and can follow new member",
  },
  {
    id: "013",
    img: img013,
    title: "title 013",
    caption: "Add and view your own post",
  },
  {
    id: "019",
    img: img019,
    title: "title 019",
    caption: "Log catch location are keep private",
  },
];

function SlideShow() {
  const { t } = useTranslation();

  return (
    <div className="relative text-center bg-neutral-500">
      <img
        src={bgBot}
        className="w-full h-full absolute mix-blend-overlay bg-contain"
        alt="bgBot"
      />
      <div className="py-2 px-2 rounded-2xl text-center mx-auto max-w-screen-xl container">
        <Carousel
          cols={1}
          rows={1}
          gap={10}
          showDots
          autoPlay={2000}
          loop={true}
          dot={MyDot}
          responsiveLayout={[
            {
              breakpoint: 2000,
              cols: 1,
              rows: 1,
              gap: 2,
              loop: true,
              autoplay: 2000,
            },
            {
              breakpoint: 1000,
              cols: 1,
              rows: 1,
              gap: 2,
              loop: true,
              autoplay: 2000,
            },
            {
              breakpoint: 500,
              cols: 1,
              rows: 1,
              gap: 2,
              loop: true,
              autoplay: 2000,
            },
          ]}
          mobileBreakpoint={350}
        >
          {datImg.map((item, i) => (
            <Carousel.Item key={item.id}>
              <div className="flex justify-center items-center">
                <img
                  src={item.img}
                  alt="img"
                  className="w-40 lg:w-60 md:w-50"
                />
              </div>
              <div className="pb-3">
                <h5>{t(item.caption)}</h5>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default SlideShow;
